<template>
  <div id="app">
    <!-- logo -->
    <div class="headerimg" v-if="headerimg != ''">
      <img :src="headerimg.image" alt style="width: 100%" />
      <img
        class="closeHeader"
        src="../../assets/e53dee2cf32a2612a6b018b42ee2733.png"
        alt
        @click="headerimg = ''"
      />
    </div>
    <div class="logo_box">
      <img :src="this.url.imgUrl + logoImg" alt />
      <!-- 头像/登录注册 -->
      <router-link to="/login" class="head_box" v-if="token == false">
        <img src="../../assets/logoin.png" alt />
        <div>登录</div>
      </router-link>
      <div class="head_img" v-if="token == true">
        <img
          class="user_img"
          src="../../assets/touxiang.png"
          alt
          @click="showQuit"
        />
        <div class="logout" v-if="quit == true" id="logout" @mouseout="none">
          <div class="personal" @click="goApp">
            <img src="../../assets/geren.png" alt />
            <div>个人中心</div>
          </div>
          <div class="quit_box" @click="quitLogin">
            <img src="../../assets/tuichu.png" alt />
            <div>退出</div>
          </div>
        </div>
      </div>
    </div>
    <div @click="none">
      <!-- 导航 -->
      <div class="nav_box">
        <div
          class="nav_item"
          v-for="item in tagList"
          :key="item.id"
          @click="changeTag(item.id)"
        >
          <div :class="{ nav_select: item.id == selectBtn }">
            {{ item.name }}
          </div>
          <img
            src="../../assets/selected.png"
            alt
            v-show="selectBtn == item.id"
          />
        </div>
      </div>
      <!-- 轮播 -->
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        :show-indicators="false"
      >
        <van-swipe-item v-for="el in banner" :key="el.id">
          <img :src="el.image" @click="openLink(el.url)" />
        </van-swipe-item>
      </van-swipe>
      <!-- 通知 -->
      <div class="notice_box" v-if="notice != ''">
        <div class="notice_left">
          <img src="../../assets/message.png" alt />
          <p>通知：</p>
        </div>
        <div class="notice_right">
          <van-notice-bar background="#fff" color="#666" :text="notice" />
        </div>
      </div>
    </div>

    <!-- 版心 -->
    <div class="content">
      <div @click="none">
        <!-- 标题 -->
        <div class="title_box">
          <div class="title">
            <p>试听课程</p>
            <div></div>
          </div>
          <!-- 更多 -->
          <div class="more_box" @click="more(1)">
            <p>更多</p>
            <img src="../../assets/more.png" alt />
          </div>
        </div>
        <!-- 试听课程 -->
        <div class="audition_box">
          <div
            class="audition_el"
            v-for="item in course_list"
            :key="item.id"
            @click="goVideo(item.id)"
          >
            <img :src="item.course_cover" alt />
            <div class="txt_box">
              <div>{{ item.name }}</div>
              <p>免费</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 标题 -->
      <div class="title_box">
        <div class="title">
          <p>网校名师</p>
          <div></div>
        </div>
        <!-- 更多 -->
        <div class="more_box" @click="more(2)">
          <p>更多</p>
          <img src="../../assets/more.png" alt />
        </div>
      </div>
      <!-- 网校名师 -->
      <div class="teacher_box">
        <div
          class="teacher_el"
          v-for="item in teacher_list.slice(0, 2)"
          :key="item.id"
          @click="goTeacher"
        >
          <div class="teacher">
            <img :src="item.m_image" alt />
          </div>

          <div class="txt_box">
            <div class="teacher_name">{{ item.name }}</div>
            <div class="line"></div>
            <div class="desc">{{ item.brief_desc }}</div>
            <div class="detail">{{ item.detail_desc }}</div>
          </div>
        </div>
      </div>
      <!-- 标题 -->
      <div class="title_box">
        <div class="title">
          <p>课程推荐</p>
          <div></div>
        </div>
        <!-- 更多 -->
        <div class="more_box" @click="more(3)">
          <p>更多</p>
          <img src="../../assets/more.png" alt />
        </div>
      </div>
      <!-- 课程推荐 -->
      <div class="course_box">
        <img
          v-for="(item, index) in recommend"
          :key="index"
          :src="item.img"
          alt
          @click="goClassInfo(item.id, item.course_id)"
        />
      </div>
      <!-- 标题 -->
      <div class="title_box">
        <div class="title">
          <p>资讯中心</p>
          <div></div>
        </div>
        <!-- 更多 -->
        <div class="more_box" @click="more(4)">
          <p>更多</p>
          <img src="../../assets/more.png" alt />
        </div>
      </div>
      <!-- 资讯中心 -->
      <div class="news_box">
        <div
          class="news_item"
          v-for="item in article_list"
          :key="item.id"
          @click="goNewInfo(item)"
        >
          <img :src="item.m_image" alt />
          <div class="news_right">
            <div>{{ item.title }}</div>
            <p>{{ item.createtime }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="foot_down_box">
      <!-- 体验 -->
      <div class="free_card" id="optionScroll">
        <div class="card_title">
          <p>高端在线评测课</p>
          <p>0元</p>
          <p>体验</p>
        </div>
        <div class="ipt">
          <el-select
            v-model="formInline.subject_id"
            placeholder="请选择您的专业"
          >
            <el-option
              v-for="item in options"
              :key="item.subject_id"
              :label="item.name"
              :value="item.subject_id"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="formInline.mobile"
            placeholder="请输入您的手机号码"
            maxlength="11"
          ></el-input>
        </div>
        <div class="submit_btn" @click="atOnce">立即体验</div>
      </div>
      <div class="free_card" id="noneCard">
        <div class="card_title">
          <p>高端在线评测课</p>
          <p>0元</p>
          <p>体验</p>
        </div>
        <div class="ipt">
          <el-select
            v-model="formInline.subject_id"
            placeholder="请选择您的专业"
          >
            <el-option
              v-for="item in options"
              :key="item.subject_id"
              :label="item.name"
              :value="item.subject_id"
            >
            </el-option>
            <!-- style="max-width: 9.75rem" -->
          </el-select>
          <el-input
            v-model="formInline.mobile"
            placeholder="请输入您的手机号码"
            maxlength="11"
          ></el-input>
        </div>
        <div class="submit_btn" @click="atOnce">立即体验</div>
      </div>
      <div class="foot_box">
        <div class="foot_content">
          <div class="app_logo">
            <img :src="this.url.imgUrl + foot_logo" alt />
            <p>立优课堂APP</p>
          </div>
          <div>试题、视频、最新考试资讯一手掌握</div>
          <img
            class="download"
            src="../../assets/xiazai.png"
            alt
            @click="goApp"
          />
          <div>
            服务热线：400-656-6816
            <br />服务时间：周一至周五9:00-12:00 14:00-17:00
            <br />版权所有：长沙立优教育科技有限公司 湘ICP备2021000943号
          </div>
        </div>
      </div>
    </div>
    <div v-show="hotshow" class="hotmask" @click="hotshow = false"></div>
    <div v-show="hotshow" class="hotimg">
      <img :src="hotimg.image" alt @click="hoturl(hotimg.url)" />
      <img
        src="../../assets/close@2x.png"
        alt
        class="hotclose"
        @click="hotshow = false"
      />
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import api from "@/api/api";
// import Swiper from "swiper";
// import "swiper/dist/css/swiper.min.css";

export default {
  data() {
    return {
      headerimg: "",
      hotimg: "",
      hotshow: false,
      logoImg: "liyou_2x.png", // logo
      tagList: [], // 标签列表
      selectBtn: "", // 导航选中
      banner: [], //banner
      notice: "", //系统公告
      course_list: [], // 试听课程
      teacher_list: [], // 师资
      recommend: [
        {
          img: `${this.url.imgUrl}xitong_yijian.png`,
          id: 1135,
          course_id: 1,
        },
        {
          img: `${this.url.imgUrl}changxue_yijian.png`,
          id: 1135,
          course_id: 2,
        },
        {
          img: `${this.url.imgUrl}baozhang_yijian.png`,
          id: 1135,
          course_id: 3,
        },
        {
          img: `${this.url.imgUrl}wuyou_yijian.png`,
          id: 1135,
          course_id: 4,
        },
      ], // 课程推荐
      article_list: [], // 资讯中心
      foot_logo: "foot_logo.png",
      options: [], // 科目列表
      formInline: {
        mobile: "",
        subject_id: "", // 选择的专业id
      },

      input: "",
      quit: false, // 退出
      token: false, //token是否存在
    };
  },

  mounted() {
    // document.getElementById("logout").style.display = "none";
    this.scroll();
    // var swiper = new Swiper(".swiper-container", {
    //   effect: "coverflow",
    //   // slidesPerView: auto,
    //   loop: true,
    //   grabCursor: true,
    //   centeredSlides: true,
    //   slidesPerView: "auto",
    //   autoplay: "100",
    //   // speed: 1000, // 轮播速度
    //   autoplayDisableOnInteraction: false,
    //   coverflow: {
    //     rotate: 10,
    //     stretch: 0,
    //     depth: 100,
    //     modifier: 1,
    //     slideShadows: false,
    //   },
    //   spaceBetween: 20,
    // });
  },
  methods: {
    // 隐藏退出按钮
    none() {
      // document.getElementById("logout").style.display = "none";
      // this.quit = !this.quit
    },

    // 页面滑动
    scroll() {
      window.onscroll = function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var bignav = document.getElementById("optionScroll"); //获取到导航栏id
        var showcard = document.getElementById("noneCard"); //获取到导航栏id
        var content = document.getElementById("high"); //获取到导航栏id
        // console.log(scrollTop);
        // if (scrollTop > 1) {
        //   bignav.style.position = "fixed";
        //   bignav.style.bottom = "0";
        //   // content.style.display = "block";
        // }
        if (scrollTop > 1300) {
          bignav.style.display = "none";
          showcard.style.display = "block";
        } else {
          bignav.style.display = "block";
          showcard.style.display = "none";
        }
      };
    },
    // 显示退出按钮
    showQuit() {
      this.quit = !this.quit;
    },
    // 退出登录
    quitLogin() {
      localStorage.removeItem("token");
      this.$toast.success({ duration: 1500, message: "退出登录成功!" });
      this.$router.push({ name: "Login" });
    },

    // banner 有链接跳转对应地址
    openLink(url) {
      let token = localStorage.getItem("token");
      if (token && url != "" && token != "") {
        window.open(url);
      }
    },
    // 点击导航
    changeTag(id) {
      this.selectBtn = id;
      this.getIndex(id);
      // this.getTeacher(id);
      // this.getCourse(id);
      if (id == 1145) {
        this.recommend = [
          {
            img: `${this.url.imgUrl}xitong_erjian.png`,
            id: 1145,
            course_id: 1,
          },
          {
            img: `${this.url.imgUrl}changxue_erjian.png`,
            id: 1145,
            course_id: 2,
          },
          {
            img: `${this.url.imgUrl}baozhang_erjian.png`,
            id: 1145,
            course_id: 3,
          },
          {
            img: `${this.url.imgUrl}wuyou_erjian.png`,
            id: 1145,
            course_id: 4,
          },
        ];
      } else if (id == 1154) {
        this.recommend = [
          {
            img: `${this.url.imgUrl}xitong_anquan.png`,
            id: 1154,
            course_id: 1,
          },
          {
            img: `${this.url.imgUrl}changxue_anquan.png`,
            id: 1154,
            course_id: 2,
          },
          {
            img: `${this.url.imgUrl}baozhang_anquan.png`,
            id: 1154,
            course_id: 3,
          },
          {
            img: `${this.url.imgUrl}wuyou_anquan.png`,
            id: 1154,
            course_id: 4,
          },
        ];
      } else if (id == 1165) {
        this.recommend = [
          {
            img: `${this.url.imgUrl}2021.png`,
            id: 1165,
            course_id: 1,
          },
          {
            img: `${this.url.imgUrl}sucheng.png`,
            id: 1165,
            course_id: 2,
          },
          {
            img: `${this.url.imgUrl}jinpai.png`,
            id: 1165,
            course_id: 3,
          },
          {
            img: `${this.url.imgUrl}VIP.png`,
            id: 1165,
            course_id: 4,
          },
        ];
      } else if (id == 1135) {
        this.recommend = [
          {
            img: `${this.url.imgUrl}xitong_yijian.png`,
            id: 1135,
            course_id: 1,
          },
          {
            img: `${this.url.imgUrl}changxue_yijian.png`,
            id: 1135,
            course_id: 2,
          },
          {
            img: `${this.url.imgUrl}baozhang_yijian.png`,
            id: 1135,
            course_id: 3,
          },
          {
            img: `${this.url.imgUrl}wuyou_yijian.png`,
            id: 1135,
            course_id: 4,
          },
        ];
      } else if (id == 1291) {
        this.recommend = [
          {
            img: `${this.url.imgUrl}tuji@2x.png`,
            id: 1291,
            course_id: 1,
          },
          {
            img: `${this.url.imgUrl}VIP@2x.png`,
            id: 1291,
            course_id: 2,
          },
        ];
      }
    },
    // 首页
    getIndex(id) {
      // console.log("id", id);
      var data = {
        course_category_id: id,
      };
      api.index(data).then((res) => {
        // console.log("首页", res);
        // if(parseInt(res.dada.code)==1){
        res.data.data.article_list.forEach((el) => {
          el.createtime = el.createtime.replace(/\s[^]+/, "");
        });
        this.article_list = res.data.data.article_list;
        this.teacher_list = res.data.data.teacher_list;
        this.course_list = res.data.data.course_list;
        // }
      });
    },
    // 获取试听课程列表
    // getCourse(id) {
    //   var data = {
    //     type: 2,
    //     course_category_id: id,
    //   };
    //   api.courses(data).then((res) => {
    //     // console.log("课程列表", res);
    //     if (parseInt(res.data.code) == 1) {
    //       res.data.data.list.forEach((el) => {
    //         el.name = el.name.replace(/^试听[- ]+([^- ])(.*)/, "$1$2");
    //       });
    //       this.course_list = res.data.data.list.slice(0, 2);
    //     }
    //   });
    // },
    // 去试听课播放页
    goVideo(id) {
      this.$router.push({ path: "/playVideo", query: { video_id: id } });
    },
    // 师资接口
    // getTeacher(id) {
    //   var data = {
    //     page: 1,
    //     page_size: 5,
    //     course_category: id,
    //   };
    //   api.faculty_list_new(data).then((res) => {
    //     // console.log("师资--", res);
    //     if (parseInt(res.data.code) == 1) {
    //       this.teacher_list = res.data.data.list;
    //     }
    //   });
    // },
    // 去名师详情页
    goTeacher() {
      // console.log(111);
      this.$router.push({
        path: "/teacher",
        query: { nav_id: this.selectBtn },
      });
    },
    // 点击更多跳转相应内容
    more(id) {
      if (id == 1) {
        this.$router.push({
          path: "/audition",
          query: { nav_id: this.selectBtn },
        });
      } else if (id == 2) {
        this.$router.push({
          path: "/teacher",
          query: { nav_id: this.selectBtn },
        });
      } else if (id == 3) {
        this.$router.push({
          path: "/course",
          query: { nav_id: this.selectBtn },
        });
      } else if (id == 4) {
        this.$router.push({
          path: "/news",
          query: { nav_id: this.selectBtn },
        });
      }
    },
    hoturl(url) {
      window.location.href = url;
      // window.location.href = 'http://www.dev.liyouedu.cn/luck/luck.html'
    },
    // 去课程推荐页面
    goClassInfo(nav, id) {
      // console.log("一级页面id",nav);
      this.$router.push({
        path: "/course",
        query: { nav_id: nav, course_id: id },
      });
      // console.log("课程推荐", id);
    },
    // 去资讯详情页
    goNewInfo(e) {
      if (e.metatype == 2) {
        window.location.href = e.content_url;
      } else {
        this.$router.push({ path: "/newsInfo", query: { nav_id: e.id } });
      }
    },

    // 0元体验提交
    atOnce() {
      var mobile = this.formInline.mobile; //用户手机号
      var ts = Date.parse(new Date()); //当前时间戳
      var cid = 2; //渠道号
      var subject_id = this.formInline.subject_id; //科目id
      var seed = cid + "-" + mobile + "-" + subject_id + "-" + ts;
      var sign = this.$md5(
        this.$md5(seed).toString().toLowerCase() + "landing_page"
      )
        .toString()
        .toLowerCase(); // 签名计算结果
      const reg = /^1[3-9][0-9]{9}$/; // 正则校验手机号
      if (!this.formInline.subject_id) {
        this.$toast.fail({
          duration: 1500,
          message: "请选择专业!",
        });
        return false;
      } else if (
        this.formInline.mobile == "" ||
        this.formInline.mobile <= 10 ||
        !reg.test(this.formInline.mobile)
      ) {
        this.$toast.fail({
          duration: 1500,
          message: "请输入正确手机号!",
        });
        return false;
      } else if (this.$cookies.get("mobile") == mobile) {
        this.$toast.fail({
          duration: 2500,
          message: "一个手机号只允许提交一次,您已提交过,请勿重复提交!",
        });
        return false;
      } else {
        var data = {
          mobile: mobile,
          ts: ts,
          cid: cid,
          subject_id: subject_id,
          sign: sign,
        };
        api.landing_site(data).then((res) => {
          if (parseInt(res.data.code) == 1) {
            this.$toast.success({
              duration: 1500,
              // message: "提交成功,一个手机号只允许提交一次,请勿重复提交!",
              message: "提交成功!",
            });
            this.formInline = {};
            this.$cookies.set("mobile", mobile, 30);
          } else {
            this.$toast.fail({
              duration: 1500,
              message: "提交失败,请重新提交!",
            });
          }
        });
      }
    },
    // 判断是安卓还是苹果
    isApp() {
      var equipmentType = "";
      var agent = navigator.userAgent.toLowerCase();
      var android = agent.indexOf("android");
      var iphone = agent.indexOf("iphone");
      var ipad = agent.indexOf("ipad");
      if (android != -1) {
        equipmentType = false;
      }
      if (iphone != -1 || ipad != -1) {
        equipmentType = true;
      }
      return equipmentType;
    },
    goApp() {
      if (this.isApp()) {
        // 苹果
        window.location.href = "https://apps.apple.com/cn/app/id1550898543";
      } else {
        // 安卓
        window.location.href =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.gamerole.wm1207";
      }
    },
  },
  beforeCreate() {
    console.log(this.url.wwwUrl);
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      // document.write("手机访问.");
      // window.location.href = url.wapUrl
    } else {
      // document.write("电脑访问.");
      // window.location.href = "http://www.baidu.com"
      window.location.href = this.url.wwwUrl;
    }
  },
  created() {
    // api.index_pic({ position: 29 }).then((res) => {
    // console.log("轮播", res);
    //   if (parseInt(res.data.code) == 1 && res.data.data.list.length > 0) {
    //     this.hotimg = res.data.data.list[0];
    //     this.hotshow = true;
    //   }
    // });
    // api.index_pic({ position: 31 }).then((res) => {
    //   if (parseInt(res.data.code) == 1) {
    //     this.headerimg = res.data.data.list[0];
    //   }
    // });
    var data = {
      course_category_id: 1135,
    };
    api.index(data).then((res) => {
      console.log(res);
      //活动弹窗
      if (
        parseInt(res.data.code) == 1 &&
        res.data.data.activity_pic_list.length > 0
      ) {
        this.hotimg = res.data.data.activity_pic_list[0];
        this.hotshow = true;
      }
      //通知
      if (res.data.data.notify_list.length > 0) {
        let a = [];
        res.data.data.notify_list.forEach((e) => a.push(e.content));
        this.notice = a.toString();
      }

      //banner 轮播
      this.banner = res.data.data.pic_list;
      //顶部图
      if (res.data.data.inform_list.length > 0) {
        this.headerimg = res.data.data.inform_list[0];
      }
      //nav导航
      this.tagList = res.data.data.category_list[0].children;
      this.tagList.push(res.data.data.category_list[1].children[0]);
      this.selectBtn = this.tagList[0].id;
      //0元体验 选项列表
      this.options = res.data.data.subject_list;
      // console.log("首页", res);
      // if(parseInt(res.dada.code)==1){

      res.data.data.article_list.forEach((el) => {
        el.createtime = el.createtime.replace(/\s[^]+/, "");
      });
      this.article_list = res.data.data.article_list;
      this.teacher_list = res.data.data.teacher_list;
      this.course_list = res.data.data.course_list;
      // }
    });

    // this.getSwiper();
    // this.getTag();
    // this.getSubject();
    if (localStorage.getItem("token")) {
      this.token = true;
    } else {
      this.token = false;
    }

    // console.log("一级id", this.tagList);
  },
};
</script>
<style lang="scss" scoped>
#app {
  overflow: hidden;
  // 测试轮播样式
  // .swiper-container {
  //   // background-color: rgb(58, 138, 251);
  //   z-index: 0;
  //   width: 100%;
  //   height: 8.75rem;
  //   padding-top: 10px;
  //   padding-bottom: 15px;
  //   .swiper-slide {
  //     background-position: center;
  //     background-size: cover;
  //     width: 80%;
  //     height: 8.75rem;
  //     background: pink;
  //     .swiper-img {
  //       // border-radius: 10px;
  //       width: 100%;
  //       height: 8.75rem;
  //       // padding: 0 .31rem;
  //     }
  //   }
  // }

  .logo_box {
    position: relative;
    width: 100%;
    height: 2.75rem;
    display: flex;
    // justify-content: center;
    align-items: center;

    // overflow: hidden;
    img {
      width: 9.13rem;
      height: 1.75rem;
      margin: auto;
    }

    // 头像/登录注册
    .head_box {
      position: absolute;
      right: 1rem;
      display: flex;
      align-items: center;

      img {
        width: 0.94rem;
        height: 0.94rem;
        margin-right: 0.25rem;
      }

      div {
        font-size: 0.94rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 1.31rem;
      }
    }

    // 登录成功头像
    .head_img {
      z-index: 999;
      position: absolute;
      top: 0.25rem;
      right: 0;
      padding-right: 0.75rem;

      .user_img {
        position: relative;
        width: 2.63rem;
        height: 2.5rem;
      }

      .logout {
        position: absolute;
        top: 2.06rem;
        right: 0.63rem;
        width: 7.63rem;
        height: 6.44rem;
        background: url("../../assets/beijing.png") no-repeat;
        background-size: 7.63rem 6.44rem;
        display: flex;
        flex-flow: column;
        align-items: center;

        .personal,
        .quit_box {
          display: flex;

          img {
            width: 1.38rem;
            height: 1.25rem;
            margin-right: 0.25rem;
          }

          div {
            min-width: 3.5rem;
            font-size: 0.88rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }

        .personal {
          margin: 1.56rem 0 1.25rem;
        }
      }

      // .logout {
      // width: 7.63rem;
      // height: 3.94rem;
      // z-index: 1;
      // display: none;
      // }
    }
  }

  // 导航
  ::-webkit-scrollbar {
    display: none;
  }

  .nav_box {
    scrollbar-width: none;
    width: 340px;
    // height: 3.75rem;
    height: 3.13rem;
    overflow-x: auto;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    // display:-webkit-box;
    white-space: nowrap;
    // padding: 0 1rem;
    // background: #fff;
    // background: pink;
    margin: auto;

    // .nav_item:first-child {
    //   margin-left: 1rem;
    // }
    // .nav_item:last-child {
    //   padding-right: 0.63rem;
    // }
    .nav_item {
      float: none;
      position: relative;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-right: 1.38rem;

      // background: pink;
      img {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
      }

      .nav_select {
        font-size: 1rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }

  // 轮播
  .my-swipe {
    width: 100%;
    // height: 8.75rem;

    .van-swipe-item {
      width: 21.4375rem;
      height: 9.375rem;
      color: #fff;
      font-size: 20px;
      // line-height: 150px;
      text-align: center;

      img {
        width: 21.4375rem;
        height: 9.375rem;
      }
    }
  }

  // 通知
  .notice_box {
    position: relative;
    width: 21.25rem;
    height: 3.13rem;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 23, 69, 0.08);
    border-radius: 8px;
    margin: 0.75rem auto 0;
    display: flex;
    align-items: center;

    .notice_left {
      display: flex;
      z-index: 1;
      background: #fff;

      img {
        width: 1.5rem;
        height: 1.5rem;
        margin: 0 0.63rem;
      }

      p {
        font-size: 0.94rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }

    .notice_right {
      position: absolute;
      left: 3.31rem;
      width: 17.88rem;

      /deep/.van-notice-bar {
        background: rgba(0, 0, 0, 0.4);
        overflow: hidden;
        padding: 0 16px 0 33px;

        /deep/.van-notice-bar__content {
          font-size: 0.94rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }

  // 版心
  .content {
    width: 21.44rem;
    // height: 187.5rem;
    margin: auto;

    // 标题
    .title_box {
      position: relative;
      width: 100%;
      height: 1.56rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2.25rem 0 1.25rem;

      .title {
        position: relative;
        width: 5rem;

        p {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #1a1d3c;
          text-align: center;
        }

        div {
          position: absolute;
          top: 0.94rem;
          width: 80px;
          height: 10px;
          background: #3e7eff;
          border-radius: 4px;
          opacity: 0.1;
          z-index: -1;
        }
      }

      // 更多
      .more_box {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;

        p:nth-child(1) {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        img {
          width: 0.5rem;
          height: 0.75rem;
          margin-left: 0.31rem;
        }
      }
    }

    // 试听课程
    .audition_box {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .audition_el {
        width: 10.38rem;
        height: 12rem;
        background: #ffffff;
        box-shadow: 0 0.13rem 0.38rem 0 rgba(4, 35, 73, 0.1);
        border-radius: 0.5rem;
        overflow: hidden;

        img {
          width: 10.38rem;
          height: 6.5rem;
        }

        .txt_box {
          width: 9.13rem;
          margin: auto;

          div {
            width: 9.13rem;
            // height: 40px;
            font-size: 0.94rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* 这里是超出几行省略 */
            overflow: hidden;
          }

          p {
            width: 2rem;
            height: 1.38rem;
            font-size: 1rem;
            font-family: PingFang SC;
            font-weight: 600;
            color: #db3f4d;
            margin-top: 0.44rem;
          }
        }
      }
    }

    // 网校名师
    .teacher_box {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .teacher_el {
        width: 10.38rem;
        height: 15.13rem;
        box-shadow: 0px 2px 6px 0px rgba(4, 35, 73, 0.1);
        border-radius: 8px;
        overflow: hidden;

        .teacher {
          width: 10.38rem;
          height: 7.25rem;

          img {
            width: 10.38rem;
            height: 7.25rem;
          }
        }

        .txt_box {
          // width: 8.25rem;
          // padding: 0 .88rem;
          margin: auto;
          display: flex;
          flex-flow: column;
          align-items: center;

          .teacher_name {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            margin: 0.56rem 0 0.31rem;
          }

          .line {
            width: 18px;
            height: 2px;
            background: #3e7eff;
            border-radius: 4px;
          }

          .desc {
            width: 136px;
            height: 16px;
            border-radius: 8px;
            opacity: 0.8;
            border: 1px solid #627fa4;
            font-size: 11px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #627fa4;
            line-height: 1rem;
            text-align: center;
            margin: 0.38rem 0 1rem;
          }

          .detail {
            width: 8.5rem;
            height: 2.5rem;
            font-size: 0.88rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #344356;
            // line-height: 20px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* 这里是超出几行省略 */
            overflow: hidden;
            text-align: left;
            // padding: 0 .75rem;
          }
        }
      }
    }

    // 课程推荐
    .course_box {
      width: 100%;

      img {
        width: 21.81rem;
        height: 6.13rem;
      }
    }

    // 资讯中心
    .news_box {
      width: 100%;
      display: flex;
      flex-flow: column;

      .news_item {
        width: 21.44rem;
        height: 6.44rem;
        background: #ffffff;
        box-shadow: 0 0.13rem 0.38rem 0 rgba(4, 35, 73, 0.1);
        border-radius: 0.5rem;
        margin-bottom: 0.63rem;
        display: flex;
        align-items: center;

        img {
          width: 7.5rem;
          height: 4.69rem;
          margin: 0 0.88rem 0 0.63rem;
        }

        .news_right {
          div {
            width: 11.81rem;
            height: 2.75rem;
            // background: pink;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-bottom: 0.94rem;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* 这里是超出几行省略 */
            overflow: hidden;
          }

          p {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #999999;
            line-height: 17px;
          }
        }
      }
    }
  }

  // 底部
  .foot_down_box {
    position: relative;
    height: 24.38rem;
    margin-top: 2.5rem;

    #optionScroll {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
    }

    #noneCard {
      display: none;
    }

    .free_card {
      // position: fixed;
      position: absolute;
      left: 0;
      right: 0;
      // bottom: 0;
      width: 21.44rem;
      background-size: 100% !important;
      height: 11.25rem;
      background: #fff url("../../assets/card.png") no-repeat;
      box-shadow: 0 0.13rem 0.38rem 0 rgba(4, 35, 73, 0.1);
      margin: auto;
      z-index: 1;
      display: flex;
      flex-flow: column;
      align-items: center;

      .card_title {
        position: absolute;
        top: 0.69rem;
        left: 0;
        right: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        p {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #333333;
        }

        p:nth-child(2) {
          color: #db3f4d;
          font-size: 1.25rem;
          margin-left: 0.75rem;
        }
      }

      .ipt {
        display: flex;
        justify-content: space-between;
        margin: 4.5rem 0 0.88rem;

        /deep/.el-input--suffix .el-input__inner {
          width: 9.75rem;
          height: 2.5rem;
          margin-right: 0.63rem;
          margin-left: 0.63rem;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }

        /deep/.el-input--suffix .el-input__inner::placeholder {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #888888;
        }

        /deep/.el-input__inner {
          width: 9.75rem;
          height: 2.5rem;
          color: pink;
          padding: 0 0.88rem;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
          // text-align: center;
        }

        /deep/.el-input__inner::placeholder {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #888888;
        }

        /deep/.el-input__icon {
          display: none;
        }
      }

      .submit_btn {
        width: 19.63rem;
        height: 2.5rem;
        background: linear-gradient(315deg, #3e7eff 0%, #88affe 100%);
        border-radius: 0.25rem;
        font-size: 0.94rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 2.5rem;
        margin: auto;
      }
    }

    .foot_box {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 16.25rem;
      background: #444444;

      .foot_content {
        // width: 20.81rem;
        height: 6.25rem;
        // background: pink;
        margin: auto;
        display: flex;
        flex-flow: column;
        align-items: center;

        .app_logo {
          display: flex;
          align-items: center;
          margin: 4.56rem 0 0.63rem;

          img {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
          }

          p {
            width: 84px;
            height: 19px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }

        div:nth-child(2) {
          width: 225px;
          height: 20px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }

        .download {
          width: 17.5rem;
          height: 2.25rem;
          margin: 0.63rem 0 1.25rem;
        }

        div:nth-child(4) {
          height: 51px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #bbbbbb;
          line-height: 17px;
          text-align: center;
        }
      }
    }
  }
}

.hotmask {
  background: #000;
  opacity: 0.7;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}

.hotimg {
  z-index: 13;
  width: 330px;
  height: 350px;
  position: fixed;
  top: 99px;
  left: 50%;
  transform: translateX(-50%);
}

.hotimg img {
  width: 100%;
}

.hotclose {
  width: 34px !important;
  position: absolute;
  top: 89px;
  right: 23px;
  height: 34px;
}

.headerimg {
  position: relative;
}

.headerimg .closeHeader {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -1px;
  right: 15px;
}
</style>
