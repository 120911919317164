<template>
  <div class="infinite-list-wrapper" style="overflow: auto">
    <div class="top_title">
      <Back></Back>
      <div>网校名师</div>
    </div>
    <img class="banner" :src="this.url.imgUrl + banner" alt="" />
    <!-- 导航 -->
    <div class="nav_box">
      <div
        class="nav_item"
        v-for="item in nav"
        :key="item.id"
        @click="changeTag(item.id)"
      >
        <div :class="{ nav_select: item.id == selectBtn }">{{ item.name }}</div>
        <img
          src="../../assets/selected.png"
          alt=""
          v-show="selectBtn == item.id"
        />
      </div>
    </div>
    <div class="content">
      <ul
        class="list teacher_box"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
      >
        <li class="teacher_item" v-for="item in teacher_list" :key="item.id">
          <img class="head_img" :src="item.m_image" alt="" />
          <div class="teacher_name">
            <div>{{ item.name }}</div>
            <div>{{ item.brief_desc }}</div>
          </div>
          <img class="top_yinhao" src="../../assets/top_yinhao.png" alt="" />
          <div class="teacher_desc">{{ item.detail_desc }}</div>
        </li>
      </ul>
    </div>
    <div
      style="
        padding-bottom: 3rem;
        text-align: center;
        font-size: 13px;
        color: #999;
      "
    >
      <p v-if="loading">加载中...</p>
      <p v-if="noMore">没有更多了</p>
    </div>
  </div>
</template>
<script>
import api from "@/api/api";
export default {
  data() {
    return {
      banner: "banner_shizi.png", // banner
      nav: [], // 导航
      selectBtn: "", // 导航选中
      teacher_list: [], // 名师
      one_nav: "", // 一级页面导航选中id
      loading: false,
      total_page_count: 9,
      teacher_list: [], // 名师
      pageData: {
        page: 1,
        page_size: 3,
        course_category: 1135,
      },
    };
  },
  computed: {
    noMore() {
      return this.pageData.page > this.total_page_count;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    //   获取标签
    getTag() {
      api.category_list().then((res) => {
        // console.log(res);
        if (parseInt(res.data.code) == 1) {
          this.nav = res.data.data.list[0].children;
          this.nav.push(res.data.data.list[1].children[0]);
          //   this.selectBtn = this.nav[0].id;
          //   this.getTeacher(this.nav[0].id);
          //   console.log(this.nav);
        }
      });
    },
    // 点击导航
    changeTag(id) {
      this.selectBtn = id;
      this.pageData.course_category = id;
      this.article_list = []
      this.pageData.page = 1
      this.load();
    },

    load() {
      this.loading = true;
      api.faculty_list_new(this.pageData).then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.total_page_count = res.data.data.total_page_count;
          this.teacher_list = [...this.teacher_list, ...res.data.data.list];
          this.pageData.page++;
          this.loading = false;
        }
      });
    },
  },
  beforeCreate() {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      // document.write("手机访问.");
      // window.location.href = url.wapUrl
    } else {
      // document.write("电脑访问.");
      // window.location.href = "http://www.baidu.com"
      window.location.href = this.url.wwwUrl;
    }
  },
  created() {
    this.getTag();
    this.selectBtn = this.$route.query.nav_id;
    this.pageData.course_category = this.$route.query.nav_id;
  },
};
</script> 
<style scoped lang="scss">
// 顶部标题
.top_title {
  width: 100%;
  height: 2.75rem;
  // display: flex;
  position: relative;
  /deep/#back_img {
    position: absolute;
    top: 0.63rem;
    left: 0.88rem;
  }
  div {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 2.75rem;
    text-align: center;
  }
}
.banner {
  width: 100%;
  height: 8.75rem;
}
// 导航
.nav_box {
  width: 100%;
  height: 3.75rem;
  overflow-x: auto;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  // padding: 0 1rem;
  background: #fff;
  margin: auto;
  .nav_item:first-child {
    padding-left: 1rem;
  }
  // .nav_item:last-child {
  //   padding-right: 1rem;
  // }
  .nav_item {
    position: relative;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    margin-right: 1.38rem;
    img {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
    }
    .nav_select {
      font-size: 1rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
.infinite-list-wrapper {
  width: 100%;
  height: 100vh;
   background: #f5f5f5;
}
.content {
  width: 100%;
  margin-top: 0.63rem;
  background: #fff;
  .teacher_box {
    width: 21.44rem;
    margin: 3.75rem auto 0;
    position: relative;
    .teacher_item {
      position: relative;
      // min-height: 14.19rem;
      background: #ffffff;
      box-shadow: 0 0.13rem 0.5rem 0 rgba(0, 13, 43, 0.08);
      border-radius: 0.5rem;
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-flow: column;
      margin-bottom: 3.75rem;
      .head_img {
        position: absolute;
        top: -2.5rem;
        width: 5rem;
        height: 5rem;
        object-fit: cover;
        border-radius: 50%;
        z-index: 1;
      }
      .teacher_name {
        margin-top: 3.13rem;
        div:nth-child(1) {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          text-align: center;
        }
        div:nth-child(2) {
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #627fa4;
          text-align: center;
        }
      }
      .top_yinhao {
        position: absolute;
        top: 6.19rem;
        left: 0.31rem;
        width: 1.75rem;
        height: 1.25rem;
      }
      .teacher_desc {
        width: 19.44rem;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #344356;
        margin-top: 0.63rem;
        margin-bottom: 0.88rem;
      }
    }
  }
}
</style>