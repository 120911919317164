<template>
  <div id="app">
    <!-- 顶部标题 -->
    <div class="top_title">
      <!-- <Back></Back> -->
      <img id="back_img" src="../../../assets/back.png" alt @click="goBack" />
      <div>试听课程</div>
      <div class="home" @click="goHome">
        <img src="../../../assets/shouye.png" alt />
        <div>首页</div>
      </div>
    </div>
    <div id="scroll" @touchmove.prevent>
      <!-- 课程标题 -->
      <div class="course_title">{{ video_title }}</div>
      <!-- 视频播放器 -->
      <div id="player"></div>
      <!-- <video
        id="play"
        controls="controls"
        controlslist="nodownload"
        :src="video_url"
      ></video>-->
      <!-- 分享链接 -->
      <div class="url_box">
        <div class="url_left">
          <div>分享链接:</div>
          <!-- <div>{{ link_url }}</div> -->
          <input type="text" :placeholder="link_url" />
        </div>
        <div class="url_right" @click="copyText">复制链接</div>
      </div>
    </div>
    <!-- 视频列表 -->
    <div class="list_box">
      <div class="list_el" v-for="item in play_video_list" :key="item.id">
        <div class="video_major">
          <img src="../../../assets/zhangjie.png" alt />
          <p>{{ item.name }}</p>
        </div>
        <div
          class="list_item_box"
          :class="{ choose: choose_video == el.id }"
          v-for="el in item.video_list"
          :key="el.id"
          @click="chooseVideo(el)"
        >
          <img class="play" src="../../../assets/bofang.png" alt />
          <p>{{ el.name }}</p>
          <div class="play_img">
            <img
              class="arrow"
              src="../../../assets/lijibofang_xin.png"
              alt
              v-show="choose_video != el.id"
            />
            <img class="gif" src="../../../assets/video_gif.gif" alt v-show="choose_video == el.id" />
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>
<script>
import api from "@/api/api";
export default {
  // 定义属性
  data() {
    return {
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      link_url: "", // 分享链接
      tagList: [], // 标签列表
      two_nav: [], // 二级导航
      video: [],
      video_title: "", // 视频标题
      polyv_vid: "",
      video_url: "", // 视频地址
      play_video_list: [],
      choose_video: "", // 视频选中
    };
  },
  // 方法集合
  methods: {
    // 点击后退
    goBack() {
      // console.log(1111);
      this.$router.go(-1);
    },
    // 点击回首页
    goHome() {
      this.$router.push({ name: "Index" });
    },
    // 试听课详情
    getVideo() {
      var data = {
        course_id: this.$route.query.video_id,
      };
      api.courses_detail(data).then((res) => {
        // console.log(res)
        if (parseInt(res.data.code) == 1) {
          res.data.data.info.name = res.data.data.info.name.replace(
            /^试听[- ]+([^- ])(.*)/,
            "$1$2"
          );
          this.video_title = res.data.data.info.name;
          if (res.data.data.info.polyv_vid && res.data.data.info.polyv_vid != '') {
            this.polyv_vid = res.data.data.info.polyv_vid;
          } else {
            this.video_url = res.data.data.info.video_url;
          }
          this.loadPlayerScript(this.loadPlayer);
          this.getTwoNav(res.data.data.info.two_coursecate_id);
        }
      });
    },
    // 点击复制
    copyText() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = this.link_url; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$toast.success({
        duration: 1500,
        message: "复制成功！",
      });
    },
    // 获取一级导航
    getOneNav() {
      var data = {
        pid: 1235,
      };
      api.course_cate(data).then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.tagList = res.data.data;
          // this.getTwoNav(1239);
        }
      });
    },
    // 二级导航
    getTwoNav(id) {
      this.video_cate_list = {};
      var data = {
        pid: id,
      };
      api.course_cate(data).then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.getCourse(id);
          // var video_cate_list = [];
          this.two_nav = res.data.data;
          this.course = res.data.data;
          this.two_selectBtn = res.data.data[0].course_category_id;
        }
      });
    },

    // 试听课程接口
    getCourse(course_category_id) {
      var data = {
        course_category_id: course_category_id,
        type: 2,
        page_size: 100,
      };
      api.courses(data).then((res) => {
        this.play_video_list = [];
        if (parseInt(res.data.code) === 1) {
          this.course.forEach((el) => {
            el.video_list = [];
            res.data.data.list.map(function (item) {
              if (el.course_category_id == item.course_category_id) {
                if (item) {
                  item.name = item.name.replace(
                    /^试听[- ]+([^- ])(.*)/,
                    "$1$2"
                  );
                  el.video_list.push(item);
                } else {
                }
              }
            });
            this.play_video_list.push(el);
            this.$forceUpdate();
          });
          this.play_video_list = this.play_video_list.filter(
            (item) => item.video_list.length !== 0
          );
        }
      });
    },
    // 保利威视频事件
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    getVidToken(vid) {
      return new Promise((resolve, reject) => {
        var data = {
          vid: vid,
          ostype:4
        };
        api.play_safe(data).then(res => {
          return resolve(res)
        })
      })

      // // var getToken = async function() {
      // // 调接口
      // var data = {
      //   ostype: 4,
      //   vid: vid,
      // };
      // // let sgin, ts;
      // var token = "";
      // await api
      //   .play_safe(data)
      //   .then((res) => {
      //     if (type == 'sign') {
      //       token = res.data.data.sign
      //     } else {
      //       token = res.data.data.ts
      //     }
      //     // token = {
      //     //   sgin: res.data.data.sgin,
      //     //   ts: res.data.data.ts
      //     // }
      //   })
      //   .catch((error) => {
      //     // return "";
      //   });
      // return token;
    },
    loadPlayer() {
      const polyvPlayer = window.polyvPlayer;
      var options = {
        wrap: "#player",
        width: '100%',
        height: '100%',
        logo: {
          logo_width: 150, //Logo宽 默认 'auto'
          logo_height: 'auto', //Logo高 默认 'auto'
          logo_url: require('../../../assets/5af8a073a9d037fa2da4563ac201333.png'), // Logo图片URL
          logo_pos: 2, //Logo显示位置，取值范围为：{0,1,2,3,4}，分别代表：隐藏、左上、右上、左下、右下
          logo_link: '', // 点击Logo的跳转链接 (选填)
          logo_alpha: 100, //Logo透明度，取值范围：(0,100)
          logoOffset: '0,0',//Logo偏移量，单位：百分比。坐标原点为播放器左上角，只在Logo位置为左上、右上时生效
          logo_resize: true //默认值为false，为true时会根据播放器窗口大小动态调整Logo的尺寸，若设置了logo宽高则该参数不生效
        }
      };
      // console.log(this.polyv_vid)
      if (this.polyv_vid != "") {
        this.getVidToken(this.polyv_vid).then(res => {
        //  console.log(res)getVidToken
        options.vid = this.polyv_vid;
        options.sign = res.data.data.sign
        options.ts = res.data.data.ts
        // options.sign = this.getVidToken(this.polyv_vid, 'sign')
        // options.ts = this.getVidToken(this.polyv_vid, 'ts')
        this.player = polyvPlayer(options);
        })
      } else {
        options.url = this.video_url;
        this.player = polyvPlayer(options);
      }
      console.log(options)
    },
    // 切换视频
    chooseVideo(el) {
      console.log(el)
      this.video_title = el.name;
      // let options = {};
      // if (el.polyv_vid && el.polyv_vid != '') {
      //   this.polyv_vid = el.polyv_vid;
      //   this.getVidToken(el.polyv_vid).then(res => {
      //     // options.sign = this.getVidToken(this.polyv_vid, 'sign')
      //   // options.ts = this.getVidToken(this.polyv_vid, 'ts')
      //    options.sign = res.data.data.sign
      //   options.ts = res.data.data.ts
      //   options.vid = el.polyv_vid;
      //   this.player.changeVid(options);
      //   })
      // } else {
      //   this.video_url = el.video_url;
      //   options.url = el.video_url;
      //   this.player.changeVid(options);
      // }
      // console.log(options)
      this.choose_video = el.id;
      // this.player.changeVid(options);
      this.link_url =
        this.url.baseUrl + "/playVideo?" + "video_id" + "=" + el.id;
      window.location.href = "/playVideo?" + "video_id" + "=" + el.id;
    },
    // 页面滑动
    scroll() {
      window.onscroll = function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var bignav = document.getElementById("scroll"); //获取到导航栏id
        // var content = document.getElementById("high"); //获取到导航栏id
        if (scrollTop > 45) {
          bignav.style.position = "fixed";
          bignav.style.top = "0";
          // content.style.display = "block";
        } else {
          bignav.style.position = "sticky";
          // content.style.display = "none";
        }
      };
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.scroll();
    this.getOneNav();
    this.getVideo();
    // 获取当前地址栏url
    this.link_url = window.location.href;
    console.log(this.url.baseUrl + "/playVideo?" + "video_id" + "=" + 1247);
    this.choose_video = this.$route.query.video_id;
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  }
};
</script>

<style scoped lang="scss">
// 顶部标题
.top_title {
  width: 100%;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /deep/#back_img {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.88rem;
    // position: absolute;
    // top: 0.63rem;
    // left: 0.88rem;
  }
  div {
    // position: absolute;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 2.75rem;
    text-align: center;
    // margin-left: -px;
  }
  .home {
    // position: absolute;
    display: flex;
    align-items: center;
    // margin-left: 50px;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
    div {
      width: 32px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-left: 5px;
      margin-right: 16px;
    }
  }
}
#scroll {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: #fff;

  .course_title {
    // width: 100%;
    height: 3.5rem;
    padding-left: 1rem;
    line-height: 3.5rem;
  }
  // 视频播发器
  #player {
    width: 100%;
    height: 13.75rem;
  }
  // 分享链接
  .url_box {
    height: 6.25rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 0.63rem solid #f5f5f5;
    padding: 0 1rem;
    .url_left {
      display: flex;
      align-items: center;
      // margin-right: .31rem;
      div:nth-child(1) {
        // min-width: 5rem;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-right: 0.5rem;
      }
      input {
        width: 10.69rem;
        height: 1.63rem;
        border-radius: 0.25rem;
        border: 0.06rem solid #e6e7e9;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      input::placeholder {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #3e7eff;
        padding-left: 0.56rem;
      }
    }
    .url_right {
      width: 4.75rem;
      height: 1.88rem;
      background: linear-gradient(315deg, #3e7eff 0%, #88affe 100%);
      border-radius: 0.94rem;
      font-size: 0.88rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.88rem;
      text-align: center;
    }
  }
}
// 视频列表
.list_box {
  // width: 100%;
  // padding-left: 1rem;
  margin-bottom: 1.63rem;
  .list_el {
    // margin-left: 1rem;
    .video_major {
      height: 3.75rem;
      // background: pink;
      border-bottom: 0.06rem solid #e5e5e5;
      display: flex;
      align-items: center;
      margin-left: 1rem;
      img {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.63rem;
      }
      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }

    .list_item_box {
      height: 3.13rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.88rem;
      color: #666666;
      .play {
        width: 1rem;
        height: 1rem;
        margin-left: 1rem;
      }
      p {
        width: 17.88rem;
        max-height: 2.63rem;
        margin: 0 0 0 0.88rem;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
      }
      .play_img {
        width: 2.5rem;
        // background: pink;
        display: flex;
        justify-content: center;
        .arrow {
          width: 1rem;
          height: 1rem;
        }
        .gif {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
    .choose {
      color: #3e7eff !important;
      background: #ebf2ff;
    }
  }
}
</style>
