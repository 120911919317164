<template>
  <div id="app">
    <!-- 登录 -->
    <div class="login_box">
      <Back></Back>
      <!-- 背景照片 -->
      <div class="login_content">
        <!-- logo -->
        <img class="logo" :src="this.url.imgUrl + logoImg" alt="" />
        <!-- 登录/注册按钮 -->
        <div class="login_register_btn">忘记密码</div>
        <!-- 注册内容 -->
        <div class="register_box" :class="{ none: !showLogin }">
          <div class="register_card">
            <div class="register_ipt">
              <div class="ipt_left">手机号</div>
              <el-input
                class="mobile"
                v-model="form.mobile"
                placeholder=""
                maxlength="11"
              ></el-input>
            </div>
            <div class="register_ipt img_code_box">
              <div class="ipt_left">图形验证码</div>
              <el-input
                class="captcha"
                v-model="form.verification_code"
                placeholder=""
                maxlength="4"
              ></el-input>
              <!-- 图形验证码 -->
              <img
                class="img_code"
                :src="captchaImg"
                alt=""
                @click="getImgCode()"
              />
            </div>
            <div class="register_ipt message_box">
              <div class="ipt_left">短信验证码</div>
              <el-input
                class="message"
                v-model="form.code"
                placeholder=""
                maxlength="4"
              ></el-input>
              <div class="get_message" @click="sendCode">{{ btntxt }}</div>
            </div>
            <div class="register_ipt">
              <div class="ipt_left">输入密码</div>
              <el-input
                class="pwd"
                v-model="form.newpassword"
                placeholder=""
                :type="pwd_type"
              ></el-input>
              <div class="pwd_img_box">
                <img
                  class="pwd_eye"
                  :src="eye_status"
                  alt=""
                  @click="showPwd(1)"
                />
                <img
                  class="pwd_suo"
                  :src="status"
                  alt=""
                  @click="delectIpt(1)"
                />
              </div>
            </div>
            <div class="register_ipt">
              <div class="ipt_left">确认密码</div>
              <el-input
                class="pwd"
                v-model="form.surepassword"
                placeholder=""
                :type="sure_pwd_type"
              ></el-input>
              <div class="pwd_img_box">
                <img
                  class="pwd_eye sure_pwd_eye"
                  :src="sure_eye_status"
                  alt=""
                  @click="showPwd(2)"
                />
                <img
                  class="pwd_suo"
                  :src="sure_status"
                  alt=""
                  @click="delectIpt(2)"
                />
              </div>
            </div>
          </div>
          <!-- 用户协议/隐私政策 -->
          <div class="theaty_box">
            <img :src="radios" alt="" @click="chooseRadio" />
            我已阅读并同意
            <p>用户协议</p>
            和
            <p>隐私政策</p>
          </div>
          <!-- 立即注册 -->
          <div class="register_btn" @click="setPwd">确认</div>
          <!-- 忘记密码 -->
          <div class="forget_pwd_box">
            <router-link to="/login">直接登录</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import crypto from "crypto";
import qs from "qs";
// import { log } from "util";
export default {
  data() {
    return {
      logoImg: "login_logo.png",
      showLogin: true,
      showRegister: false,
      radio: false,
      form: {
        mobile: "", //手机号
        verification_code: "", // 图形验证码
        code: "", // 短信验证码
        newpassword: "", //新密码
        surepassword: "", //确认密码
        nonce_str: "", // 随机字符串
      },
      captchaImg: require("../../assets/tuxing.png"), // 图形验证码
      btntxt: "获取验证码", // 获取短信验证码,
      status: require("../../assets/suo.png"), // 密码框输入状态
      eye_status: require("../../assets/eye_close.png"), // 密码显示状态图片
      sure_status: require("../../assets/suo.png"), // 确认密码框输入状态
      sure_eye_status: require("../../assets/eye_close.png"), // 密码显示状态图片
      pwd_type: "password", // 密码框type属性
      sure_pwd_type: "password", // 确认密码框type属性
      radios: require("../../assets/radio.png"), //隐私协议单选
      choose_radio: false,
      time: 60, //倒计时
    };
  },
  watch: {
    // 监听新密码输入,切换状态
    "form.newpassword"() {
      if (this.form.newpassword.length > 0) {
        this.status = require("../../assets/close.png");
        document.querySelector(".pwd_eye").style.display = "block";
      } else {
        this.status = require("../../assets/suo.png");
        document.querySelector(".pwd_eye").style.display = "none";
      }
    },
    // 监听确认密码输入,切换状态
    "form.surepassword"() {
      if (this.form.surepassword.length > 0) {
        this.sure_status = require("../../assets/close.png");
        document.querySelector(".sure_pwd_eye").style.display = "block";
      } else {
        this.sure_status = require("../../assets/suo.png");
        document.querySelector(".sure_pwd_eye").style.display = "none";
      }
    },
  },
  // 方法集合
  methods: {
    // 点击切换图形验证码
    getImgCode() {
      var md5 = crypto.createHash("md5");
      md5.update(Math.ceil(Math.random() * 10) + "" + Date.now());
      this.form.nonce_str = md5.digest("hex");
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$toast.fail("请输入正确手机号!");
        return false;
      } else {
        this.captchaImg =
          this.url.apiUrl +
          "/api/verification_code/verification?nonce_str=" +
          this.form.nonce_str +
          "&mobile=" +
          this.form.mobile +
          "&w=" +
          90 +
          "&h=" +
          36;
      }
    },
    // 发送短信验证码
    sendCode() {
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$toast.fail({ duration: 1500, message: "请输入正确手机号!" });
        return false;
      } else if (this.form.verification_code == "") {
        this.$toast.fail({
          duration: 1500,
          message: "请输入正确图形验证码!",
        });
        return;
      } else {
        var data = {
          mobile: this.form.mobile,
          event: "resetpwd",
          verification_code: this.form.verification_code,
          nonce_str: this.form.nonce_str,
        };
        // this.qs.stringify(
        api.sms(data).then((res) => {
          if (parseInt(res.data.code) == 1) {
            // console.log(res);
            this.$toast.fail({ duration: 1500, message: res.data.msg });
            this.time = 60;
            this.timer();
          } else {
            this.$toast.fail({ duration: 1500, message: res.data.msg });
          }
        });
      }
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }
    },
    // 密码显示状态
    showPwd(id) {
      if (id == 1) {
        this.pwd_type = this.pwd_type === "password" ? "text" : "password";
        this.eye_status =
          this.eye_status === require("../../assets/eye_close.png")
            ? require("../../assets/eye_open.png")
            : require("../../assets/eye_close.png");
      } else {
        this.sure_pwd_type =
          this.sure_pwd_type === "password" ? "text" : "password";
        this.sure_eye_status =
          this.sure_eye_status === require("../../assets/eye_close.png")
            ? require("../../assets/eye_open.png")
            : require("../../assets/eye_close.png");
      }
    },
    // 删除输入框内容
    delectIpt(id) {
      // if (this.status == require("../../assets/suo.png")) {
      if (id == 1) {
        this.form.newpassword = "";
      } else {
        this.form.surepassword = "";
      }

      // }
    },
    // 选择协议
    chooseRadio() {
      this.choose_radio = !this.choose_radio;
      // console.log(this.choose_radio);
      if (this.choose_radio == true) {
        this.radios = require("../../assets/radio_choose.png");
      } else {
        this.radios = require("../../assets/radio.png");
      }
    },
    // 修改密码
    setPwd() {
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$toast.fail({ duration: 1500, message: "请输入正确手机号" });
        return false;
      } else if (this.form.verification_code == "") {
        this.$toast.fail({ duration: 1500, message: "请输入正确图形验证码" });
        return false;
      } else if (this.form.code == "") {
        this.$toast.fail({ duration: 1500, message: "请输入正确短信验证码" });
        return false;
      } else if (
        this.form.newpassword == "" &&
        this.form.newpassword.length < 6
      ) {
        this.$toast.fail({
          duration: 1500,
          message: "请输入新密码,且长度不少于6位",
        });
        return false;
      } else if (
        this.form.surepassword == "" &&
        this.form.surepassword.length < 6
      ) {
        this.$toast.fail({
          duration: 1500,
          message: "请输入确认密码,且长度不少于6位",
        });
        return false;
      } else if (this.form.newpassword != this.form.surepassword) {
        this.$toast.fail({
          duration: 1500,
          message: "两次密码不一致!",
        });
        return false;
      } else if (this.choose_radio == false) {
        this.$toast.fail({ duration: 1500, message: "请勾选协议!" });
        return false;
      } else {
        var data = {
          mobile: this.form.mobile, // 手机号
          captcha: this.form.code, // 验证码
          newpassword: this.form.newpassword, // 科目id
        };
        api.resetpwd(qs.stringify(data)).then((res) => {
          // console.log("修改", res);
          if (parseInt(res.data.code) == 1) {
            this.$toast.success({
              duration: 1500,
              message: "重置密码成功!",
            });
            this.form = {};
            this.$router.push({ name: "JumpLogin" });
          } else {
            this.$toast.fail({
              duration: 1500,
              message: res.data.msg,
            });
            this.form = {};
          }
        });
      }
      // this.showRegister == true;
      // this.showLogin == false;
      // console.log("注册", 222);
    },
  },
  beforeCreate() {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      // document.write("手机访问.");
      // window.location.href = url.wapUrl
    } else {
      // document.write("电脑访问.");
      // window.location.href = "http://www.baidu.com"
      window.location.href = this.url.wwwUrl;
    }
  },

  created() {
    // this.getSubject();
  },
};
</script>

<style scoped lang="scss">
.login_box {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background: url("../../assets/background.png") no-repeat;
  background-size: 100% 100%;
  /deep/#back_img {
    margin-left: 1.13rem;
    margin-top: 0.63rem;
  }
  .login_content {
    width: 18.75rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    .logo {
      width: 6rem;
      height: 6rem;
      // margin-top: 3.94rem;
      margin: 3.94rem 0 2.5rem;
    }
    // 登录/注册按钮
    .login_register_btn {
      width: 7.81rem;
      display: flex;
      justify-content: space-around;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #3e7eff;
      line-height: 28px;
      margin-bottom: 1.5rem;
      text-align: center;
    }
    .none {
      display: none;
    }
    // 忘记密码
    .forget_pwd_box {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      p {
        margin-right: 0.31rem;
      }
      a {
        color: #4285f5;
      }
    }
    // 注册内容
    .register_box {
      width: 100%;
      height: 100px;
      .register_card {
        width: 100%;
        // height: 15.63rem;
        background: #fff;
        border-radius: 4px;
        .register_ipt {
          display: flex;
          align-items: center;
          // width: 16.75rem;
          padding: 0 16px;
          // padding-right: 1rem;
          .ipt_left {
            min-width: 4.38rem;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          /deep/.el-input__inner {
            width: 100%;
            height: 3.13rem;
            border: none;
            padding: 0 16px;
            // text-align: center;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            // padding-right: 1rem;
          }
          /deep/.el-input__inner::placeholder {
            text-align: left;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          .pwd_img_box {
            // width: 3rem;
            display: flex;
            // justify-content: flex-end;
            // margin-right: 1rem;
            // background: pink;
            .pwd_eye {
              width: 1.25rem;
              height: 1.25rem;
              margin-right: 0.75rem;
              display: none;
            }
            .pwd_suo {
              width: 1.13rem;
              height: 1.13rem;
            }
          }
          // /deep/.mobile .el-input__inner {
          //   background: url("../../assets/yonghu.png") right no-repeat;
          //   background-size: 1.25rem;
          // }
          // /deep/.pwd .el-input__inner {
          //   background: url("../../assets/suo.png") right no-repeat;
          //   background-size: 1.25rem;
          // }
        }
        // 图形验证码
        .img_code_box,
        .message_box {
          position: relative;
          display: flex;
          align-items: center;
        }
        .img_code {
          position: absolute;
          right: 16px;
        }
        // 短信验证码
        .get_message {
          min-width: 5.63rem;
          height: 2.25rem;
          background: #e2ecff;
          border-radius: 0.25rem;
          font-size: 0.88rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #3e7eff;
          line-height: 2.25rem;
          text-align: center;
        }
      }
      // 协议
      .theaty_box {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        margin: 0.88rem 0 1.25rem;
        img {
          width: 1.13rem;
          height: 1.13rem;
        }
        p {
          color: #3e7eff;
        }
      }
      // 立即注册
      .register_btn {
        width: 300px;
        height: 44px;
        background: linear-gradient(270deg, #aec7fb 0%, #3e7eff 100%);
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
        margin-bottom: 0.88rem;
      }
    }
  }
}
</style>
