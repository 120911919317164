<template>
  <div id="app">
    <!-- 顶部标题 -->
    <div class="top_title">
      <Back></Back>
      <div>资讯中心</div>
    </div>
    <div class="info_box">
      <div class="info_title">{{ news_info.title }}</div>
      <div class="info_time">{{ news_info.createtime }}</div>
      <div class="info_content" v-html="news_info.content"></div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  // 定义属性
  data() {
    return {
      news_info: [],
    };
  },
  // 方法集合
  methods: {
    getNewsInfo() {
      var data = {
        article_id: this.$route.query.nav_id,
      };
      api.detail(data).then((res) => {
        this.news_info = res.data.data;
      });
    },
  },
  beforeCreate() {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      // document.write("手机访问.");
      // window.location.href = url.wapUrl
    } else {
      // document.write("电脑访问.");
      // window.location.href = "http://www.baidu.com"
      window.location.href = this.url.wwwUrl;
    }
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getNewsInfo();
  },
};
</script>

<style scoped lang="scss">
#app {

  //   overflow: hidden;
  //   background: #f5f5f5;
  // 顶部标题
  .top_title {
    width: 100%;
    height: 2.75rem;
    // display: flex;
    position: relative;

    /deep/#back_img {
      position: absolute;
      top: 0.63rem;
      left: 0.88rem;
    }

    div {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 2.75rem;
      text-align: center;
    }
  }

  .info_box {
    width: 20.94rem;
    margin: auto;

    .info_title {
      font-size: 1.25rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    .info_time {
      font-size: 0.75rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      margin: 0.63rem 0 1.44rem;
    }

    .info_content {
      margin-bottom: 1.44rem;
    }
  }
}
</style>
