<template>
  <div id="app">
    <div class="box">
      <img class="ok" src="../../../assets/Ok.png" alt="" />
      <div class="time_box">
        <div>新密码已生效！</div>
        <div>
          <p>{{ count }}秒</p>
          后自动返回登录页
        </div>
      </div>
      <button type="button" @click="goLogin">返回登录页</button>
    </div>
  </div>
</template>

<script>
export default {
  // 定义属性
  data() {
    return {
      count: "3",
    };
  },
  // 方法集合
  methods: {
    getCount() {
      const TIME_COUNT = 3;
      this.count = TIME_COUNT;
      let clock = window.setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--;
        } else {
          // this.$router.push("/login");
          // this.$router.push({ name: "Login" });
          // this.goLogin()
          this.$router.push({ path: "/login" });
        }
      }, 1000);
    },
    goLogin() {
      this.$router.push({ name: "Login" });
    },
  },
  beforeCreate() {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      // document.write("手机访问.");
      // window.location.href = url.wapUrl
    } else {
      // document.write("电脑访问.");
      // window.location.href = "http://www.baidu.com"
      window.location.href = this.url.wwwUrl;
    }
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getCount();
  },
};
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 100vh;
  background: url("../../../assets/background.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  .ok {
    width: 12.5rem;
    height: 9.5rem;
    // margin-top: 7.13rem;
    margin: 7.13rem 0 2.88rem;
  }
  .time_box {
    width: 8.88rem;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    text-align: center;
    div:nth-child(2) {
      display: flex;
      p {
        color: #3e7eff;
        margin-right: 0.31rem;
      }
    }
  }
  button {
    width: 268px;
    height: 44px;
    background: linear-gradient(270deg, #aec7fb 0%, #3e7eff 100%);
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-top: 1rem;
  }
}
</style>
