<template>
  <div id="app">
    <!-- 顶部标题 -->
    <div class="top_title">
      <Back></Back>
      <div>试听课程</div>
    </div>
    <img class="banner" :src="this.url.imgUrl + banner" alt="" />
    <div id="high"></div>
    <div id="fixPara">
      <!-- 一级导航 -->
      <div class="nav_box">
        <div
          class="nav_item"
          v-for="item in tagList"
          :key="item.course_category_id"
          @click="changeTag(item.course_category_id)"
        >
          <div :class="{ nav_select: item.course_category_id == selectBtn }">
            {{ item.name }}
          </div>
          <img
            src="../../assets/selected.png"
            alt=""
            v-show="selectBtn == item.course_category_id"
          />
        </div>
      </div>
      <div class="line"></div>
      <!-- 二级导航 -->
      <div class="two_nav_box">
        <div class="auto_box">
          <div
            class="two_nav_el"
            :class="{ two_select: two_selectBtn == item.course_category_id }"
            v-for="item in two_nav"
            :key="item.course_category_id"
            @click="changeTwoNav(item.course_category_id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 视频列表 -->
    <div class="list_box">
      <div
        class="list_el"
        v-for="(item, index) in play_video_list"
        :key="index"
        :id="item.course_category_id"
      >
        <div class="video_major">
          <img src="../../assets/zhangjie.png" alt="" />
          <p>{{ item.name }}</p>
        </div>
        <div
          class="list_item_box"
          v-for="el in item.video_list"
          :key="el.id"
          @click="goPlayVideo(el.id)"
        >
          <div class="list_item_txt">
            <img class="play" src="../../assets/bofang.png" alt="" />
            <div>{{ el.name }}</div>
            <img class="arrow" src="../../assets/lijibofang.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  // 定义属性
  data() {
    return {
      tagList: [], // 标签列表
      two_nav: [], // 二级导航
      banner: "banner_shiting.png", // banner
      course_list: [], // 试听课程
      selectBtn: "", // 一级导航选中
      two_selectBtn: "", // 二级导航选中
      video_list: [],
      play_video_list: [],
    };
  },
  // 方法集合
  methods: {
    // 页面滑动
    scroll() {
      window.onscroll = function() {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var bignav = document.getElementById("fixPara"); //获取到导航栏id
        var content = document.getElementById("high"); //获取到导航栏id
        // console.log(scrollTop);
        if (scrollTop > 180) {
          bignav.style.position = "fixed";
          bignav.style.top = "0";
          content.style.display = "block";
        } else {
          bignav.style.position = "sticky";
          content.style.display = "none";
        }
      };
    },
    getId(itemId) {
      return itemId;
    },
    // 点击导航栏跳转到固定位置
    changeTwoNav(domId) {
      // this.is_two = domId;
      this.two_selectBtn = domId;
      // 当前窗口正中心位置到指定dom位置的距离

      //页面滚动了的距离
      let height =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      //指定dom到页面顶端的距离
      let dom = document.getElementById(domId);
      let domHeight = dom.offsetTop - 180;

      //滚动距离计算
      var S = Number(height) - Number(domHeight);

      //判断上滚还是下滚
      if (S < 0) {
        //下滚
        S = Math.abs(S);
        window.scrollBy({
          top: S,
          behavior: "smooth",
        });
      } else if (S == 0) {
        //不滚
        window.scrollBy({
          top: 0,
          behavior: "smooth",
        });
      } else {
        //上滚
        S = -S;
        window.scrollBy({
          top: S,
          behavior: "smooth",
        });
      }
    },
    // 获取导航标签数据
    getTag() {
      api.category_list().then((res) => {
        // console.log(res);
        if (parseInt(res.data.code) == 1) {
          this.tagList = res.data.data.list[0].children;
          this.tagList.push(res.data.data.list[1].children[0]);
          this.selectBtn = this.tagList[0].id;
          // this.getCourse(this.tagList[0].id);
          // console.log(this.tagList);
        }
      });
    },
    getOneNav() {
      // 一级导航接口
      var data = {
        pid: 1235,
      };
      api.course_cate(data).then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.tagList = res.data.data;
          this.selectBtn = res.data.data[0].course_category_id;
          this.getTwoNav(res.data.data[0].course_category_id);
        }
      });
    },
    // 二级导航
    getTwoNav(id) {
      this.video_cate_list = {};
      var data = {
        pid: id,
      };
      api.course_cate(data).then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.getCourse(id);
          // var video_cate_list = [];
          this.two_nav = res.data.data;
          this.course = res.data.data;
          this.two_selectBtn = res.data.data[0].course_category_id;
        }
      });
    },

    // 试听课程接口
    getCourse(course_category_id) {
      var data = {
        course_category_id: course_category_id,
        type: 2,
        page_size: 100,
      };
      api.courses(data).then((res) => {
        this.play_video_list = [];
        if (parseInt(res.data.code) === 1) {
          this.course.forEach((el) => {
            el.video_list = [];
            res.data.data.list.map(function(item) {
              if (el.course_category_id == item.course_category_id) {
                if (item) {
                  item.name = item.name.replace(
                    /^试听[- ]+([^- ])(.*)/,
                    "$1$2"
                  );
                  el.video_list.push(item);
                } else {
                }
              }
            });
            this.play_video_list.push(el);
            this.$forceUpdate();
          });
          this.play_video_list = this.play_video_list.filter(
            (item) => item.video_list.length !== 0
          );
        }
      });
    },
    // 点击一级导航
    changeTag(id) {
      this.selectBtn = id;
      // this.getCourse(id);
      this.getTwoNav(id);
    },
    // 点击二级导航
    // changeTwoNav(id) {

    //   // this.getCourse(id);
    // },
    // 去视频播放页
    goPlayVideo(id) {
      this.$router.push({ path: "/playVideo", query: { video_id: id } });
    },
  },
  beforeCreate() {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      // document.write("手机访问.");
      // window.location.href = url.wapUrl
    } else {
      // document.write("电脑访问.");
      // window.location.href = "http://www.baidu.com"
      window.location.href = this.url.wwwUrl;
    }
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.initHeight();
    this.scroll();
    this.getOneNav();
    this.getTwoNav(this.$route.query.nav_id);
  },
};
</script>

<style scoped lang="scss">
#app {
  overflow: hidden;

  // 顶部标题
  .top_title {
    width: 100%;
    height: 2.75rem;
    // display: flex;
    position: relative;
    /deep/#back_img {
      position: absolute;
      top: 0.63rem;
      left: 0.88rem;
    }
    div {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 2.75rem;
      text-align: center;
    }
  }
  .banner {
    width: 100%;
    height: 8.75rem;
  }
  #high {
    display: none;
    height: 6.25rem;
  }
  #fixPara {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    // 导航
    .nav_box {
      width: 100%;
      height: 3.75rem;
      overflow-x: auto;
      display: flex;
      align-items: center;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      // padding: 0 1rem;
      background: #fff;
      .nav_item:first-child {
        padding-left: 1rem;
      }
      // .nav_item:last-child {
      //   padding-right: 1rem;
      // }
      .nav_item {
        position: relative;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-right: 1.38rem;
        img {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
        }
        .nav_select {
          font-size: 1rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
    }
    .line {
      width: 22.44rem;
      height: 0.06rem;
      background: #eee;
      float: right;
    }
    // 二级导航
    .two_nav_box {
      width: 100%;
      height: 3.75rem;
      background: #fff;
      border-bottom: 0.63rem solid #f5f5f5;
      margin: auto;
      .auto_box {
        width: 98%;
        height: 3.75rem;
        display: flex;
        align-items: center;
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        // padding-right: 2rem;
        // margin: auto;
        .two_nav_el:first-child {
          margin-left: 1rem;
        }
        // .two_nav_el:last-child {
        //   margin-right: 3.06rem;
        // }
        .two_nav_el {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #999999;
          border-radius: 18px;
          border: 1px solid #dddddd;
          padding: 0.25rem 1.38rem;
          margin-right: 0.63rem;
        }
        .two_select {
          background: #3e7eff;
          color: #ffffff;
          border: none;
        }
      }
    }
  }
}
// 视频列表
.list_box {
  margin-bottom: 1.81rem;
  margin: auto;
  .list_el {
    padding-left: 1rem;
    margin: auto;
    .video_major {
      height: 3.75rem;
      // background: pink;
      border-bottom: 0.06rem solid #e5e5e5;
      display: flex;
      align-items: center;
      img {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.63rem;
      }
      p {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .list_item_box {
      // height: 3.13rem;
      max-height: 4.38rem;
      display: flex;
      align-items: center;
      .list_item_txt {
        display: flex;
        align-items: center;
        padding: 0.88rem 0;
        .play {
          width: 1rem;
          height: 1rem;
        }
        div {
          width: 17.88rem;
          max-height: 2.63rem;
          margin: 0 1.19rem 0 0.88rem;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .arrow {
          width: 0.5rem;
          height: 0.75rem;
        }
      }
    }
  }
}
</style>
