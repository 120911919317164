<template>
  <div class="infinite-list-wrapper" style="overflow: auto">
    <!-- 顶部标题 -->
    <div class="top_title">
      <Back></Back>
      <div>资讯中心</div>
    </div>
    <img class="banner" :src="this.url.imgUrl + banner" alt="" />
    <!-- 导航 -->
    <div class="nav_box">
      <div
        class="nav_item"
        v-for="item in nav"
        :key="item.id"
        @click="changeTag(item.id)"
      >
        <div :class="{ nav_select: item.id == selectBtn }">{{ item.name }}</div>
        <img
          src="../../assets/selected.png"
          alt=""
          v-show="selectBtn == item.id"
        />
      </div>
    </div>

    <!-- 内容区 -->
    <div class="content">
      <ul
        class="list new_box"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
      >
        <div class="news_title">考试资讯</div>

        <li
          class="news_item"
          v-for="item in article_list"
          :key="item.id"
          @click="goNewsInfo(item)"
        >
          <img class="news_left" :src="item.image" alt="" />
          <div class="news_right">
            <div>{{ item.title }}</div>
            <div>{{ item.createtime }}</div>
          </div>
        </li>
      </ul>
    </div>
     <div
      style="
        padding-bottom: 3rem;
        text-align: center;
        font-size: 13px;
        color: #999;
      "
    >
      <p v-if="loading">加载中...</p>
      <p v-if="noMore">没有更多了</p>
    </div>
    <!-- <Foot></Foot> -->
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  // 定义属性
  data() {
    return {
      total_page_count: 9,
      loading: false,
      banner: "banner_zixun.png", // banner
      nav: [], // 导航
      selectBtn: "", // 导航选中
      article_list: [], // 资讯列表
      pageData: {
        page: 1,
        page_size: 5,
        course_category_id: 1135,
      },
    };
  },
    computed: {
    noMore() {
      return this.pageData.page > this.total_page_count;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  // 方法集合
  methods: {
    //   获取标签
    getTag() {
      api.category_list().then((res) => {
        // console.log(res);
        if (parseInt(res.data.code) == 1) {
          this.nav = res.data.data.list[0].children;
          this.nav.push(res.data.data.list[1].children[0]);
        }
      });
    },
    // 点击导航
    changeTag(id) {
      this.selectBtn = id;
      this.pageData.course_category_id = id;
      this.article_list = []
      this.pageData.page = 1
      this.load();
    },
    // 获取资讯列表
    load() {
      this.loading = true;
      api.article(this.pageData).then((res) => {
        // console.log("资讯--", res);
        if (parseInt(res.data.code) == 1) {
          this.total_page_count = res.data.data.total_page_count;
          res.data.data.list.forEach((el) => {
            el.createtime = el.createtime.replace(/\s[^]+/, "");
          });
          this.article_list = [...this.article_list, ...res.data.data.list];
          this.pageData.page++;
          this.loading = false;
        }
      });
    },
    // 去资讯详情页
    goNewsInfo(e) {
       if (e.metatype == 2) {
        window.location.href = e.content_url
      } else {
        this.$router.push({ path: "/newsInfo", query: { nav_id: e.id } });
      }
    },
  },
  beforeCreate() {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      // document.write("手机访问.");
      // window.location.href = url.wapUrl
    } else {
      // document.write("电脑访问.");
      // window.location.href = "http://www.baidu.com"
      window.location.href = this.url.wwwUrl;
    }
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTag();
    this.selectBtn = this.$route.query.nav_id;
    this.pageData.course_category_id = this.$route.query.nav_id;
    // this.getNews();
  },
};
</script>

<style scoped lang="scss">
.infinite-list-wrapper {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;

  // 顶部标题
  .top_title {
    width: 100%;
    height: 2.75rem;
    // display: flex;
    position: relative;
    /deep/#back_img {
      position: absolute;
      top: 0.63rem;
      left: 0.88rem;
    }
    div {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 2.75rem;
      text-align: center;
    }
  }
  .banner {
    width: 100%;
    height: 8.75rem;
  }
  // 导航
  .nav_box {
    width: 100%;
    height: 3.75rem;
    overflow-x: auto;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    // padding: 0 1rem;
    background: #fff;
    margin: auto;
    .nav_item:first-child {
      padding-left: 1rem;
    }
    // .nav_item:last-child {
    //   padding-right: 1rem;
    //   margin-right: 0;
    // }
    .nav_item {
      position: relative;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-right: 1.38rem;
      img {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
      }
      .nav_select {
        font-size: 1rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  // 内容区
  .content {
    width: 100%;
    background: #fff;
    margin: 0.63rem auto 0;

    .new_box {
      width: 21.44rem;
      margin: 1rem auto 1.13rem;
      .news_title {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      .news_item {
        height: 6.94rem;
        border-bottom: 0.06rem solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .news_left {
          width: 7.5rem;
          height: 4.69rem;
        }
        .news_right {
          div:nth-child(1) {
            width: 13.06rem;
            min-height: 2.75rem;
            font-size: 1rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-bottom: 0.88rem;
          }
          div:nth-child(2) {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
      .news_item:last-child {
        border: none;
      }
    }
  }
}
</style>
