<template>
  <div id="app">
    <!-- 顶部标题 -->
    <div class="top_title">
      <Back></Back>
      <div>课程推荐</div>
    </div>
    <img class="banner" :src="this.url.imgUrl + banner" alt="" />
    <!-- 一级导航 -->
    <div class="nav_box">
      <div
        class="nav_item"
        v-for="item in tagList"
        :key="item.course_category_id"
        @click="changeTag(item.id)"
      >
        <div :class="{ nav_select: item.id == selectBtn }">
          {{ item.name }}
        </div>
        <img
          src="../../assets/selected.png"
          alt=""
          v-show="selectBtn == item.id"
        />
      </div>
    </div>
    <div class="line"></div>
    <!-- 二级导航 -->
    <div class="two_nav_box">
      <div class="auto_box">
        <div
          class="two_nav_el"
          :class="{ two_select: two_selectBtn == item.major_id }"
          v-for="(item, index) in two_nav"
          :key="index"
          @click="changeTwoNav(item.major_id, item)"
        >
          {{ item.tag_name }}
        </div>
      </div>
    </div>
    <!-- 内容区 -->
    <div class="content">
      <div class="course_img">
        <img :src="course_list.img" alt="" />
        <!-- <img :src="two_nav[0].img" alt="" v-show="choose_two == false" /> -->
        <!-- <img :src="course_list.img" alt="" v-show="choose_two == true" /> -->
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  // 定义属性
  data() {
    return {
      tagList: [], // 标签列表
      two_nav: [], // 二级导航
      banner: "banner_kecheng.png", // banner
      course_list: [], // 试听课程
      selectBtn: "", // 一级导航选中
      two_selectBtn: 1, // 二级导航选中
      choose_two: false, // 选择二级
      course_list: {}, // 课程列表
      major_id: "", //首页课程推荐id
      major_img: "",
    };
  },
  // 方法集合
  methods: {
    // 获取导航标签数据
    getTag() {
      api.category_list().then((res) => {
        // console.log("导航-----", res);
        if (parseInt(res.data.code) == 1) {
          this.tagList = res.data.data.list[0].children;
          this.tagList.push(res.data.data.list[1].children[0]);
          this.getTwoNav(this.$route.query.nav_id);
        }
      });
    },
    // 获取二级导航
    getTwoNav(id) {
      var data = {
        course_category: id,
      };
      api.recommmend(data).then((res) => {
        // console.log("二级导航", res);
        if (parseInt(res.data.code) == 1) {
          this.two_nav = res.data.data.list;
          for (let v of res.data.data.list) {
            if (this.$route.query.course_id == v.major_id) {
              this.course_list.img = v.img;
              // console.log("照片", this.course_list.img);
              this.two_selectBtn = v.major_id;
              return;
            } else if (!this.$route.query.course_id) {
              // console.log("v", v);
              this.course_list.img = res.data.data.list[0].img;
            }
          }
        }
      });
    },
    // 点击一级导航
    changeTag(id) {
      // console.log("id", id);
      this.selectBtn = id;
      // this.getCourse(id);
      this.getTwoNav(id);
      this.choose_two = false;
      this.two_selectBtn = 1;
    },
    // 点击二级导航
    changeTwoNav(id, el) {
      // console.log(el);
      this.course_list.img = el.img;
      this.choose_two = true;
      this.two_selectBtn = id;
    },
  },
  beforeCreate() {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      // document.write("手机访问.");
      // window.location.href = url.wapUrl
    } else {
      // document.write("电脑访问.");
      // window.location.href = "http://www.baidu.com"
      window.location.href = this.url.wwwUrl;
    }
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTag();
    this.selectBtn = this.$route.query.nav_id;
    // this.major_id = this.$route.query.course_id;
    // console.log("专业id", this.major_id);
  },
};
</script>

<style scoped lang="scss">
#app {
  overflow: hidden;
  .top_title {
    width: 100%;
    height: 2.75rem;
    // display: flex;
    position: relative;
    /deep/#back_img {
      position: absolute;
      top: 0.63rem;
      left: 0.88rem;
    }
    div {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 2.75rem;
      text-align: center;
    }
  }
  .banner {
    width: 100%;
    height: 8.75rem;
  }
  // 导航
  .nav_box {
    width: 100%;
    height: 3.75rem;
    overflow-x: auto;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    // padding: 0 1rem;
    background: #fff;
    .nav_item:first-child {
      padding-left: 1rem;
    }
    // .nav_item:last-child {
    //   padding-right: 1rem;
    // }
    .nav_item {
      position: relative;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-right: 1.38rem;
      img {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
      }
      .nav_select {
        font-size: 1rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .line {
    width: 22.44rem;
    height: 0.06rem;
    background: #eee;
    float: right;
  }
  // 二级导航
  .two_nav_box {
    width: 100%;
    height: 3.75rem;
    background: #fff;
    border-bottom: 0.63rem solid #f5f5f5;
    margin: auto;
    .auto_box {
      width: 98%;
      height: 3.75rem;
      display: flex;
      align-items: center;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      // padding-right: 2rem;
      // margin: auto;
      .two_nav_el:first-child {
        margin-left: 1rem;
      }
      .two_nav_el {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        border-radius: 18px;
        border: 1px solid #dddddd;
        padding: 0.25rem 1.38rem;
        margin-right: 0.63rem;
      }
      .two_select {
        background: #3e7eff;
        color: #ffffff;
        border: none;
      }
    }
  }
}
// 内容区
.content {
  width: 100%;
  .course_img {
    width: 100%;
    margin: 1rem auto 2.5rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    img {
      width: 22.44rem;
    }
  }
}
</style>
